import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./TopBar.css";

function TopBar() {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle burger menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (

    <header className="top-bar">
      <div className="logo"><img src="https://content.publishingconcepts.com/Common/CONnect/logos/SVG/CONnect logo_white.svg" alt="Logo" /></div>
      <nav >
          {/* Burger menu for mobile */}
        <div className={`burger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>  
          <li onClick={closeMenu}><Link to="/">Home</Link>  {/* Link to the Home route */}</li>
          <li onClick={closeMenu}><Link to="/registration">Registration</Link>  {/* Link to the Register route */}</li>
          <li onClick={closeMenu}><Link to="/contact">Contact</Link>  {/* Link to the Contact route */}</li>    
        </ul>
      </nav>

    </header>
    
  );
}

export default TopBar;
